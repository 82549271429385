import React from 'react';
import Typography from '@mui/material/Typography';

const Datos = () => {
  const styles = {
    container: {
      backgroundColor: '#e03e3e',
      color: 'white',            
      padding: '10px',           
      textAlign: 'center',       
      display: 'flex',           
      justifyContent: 'center',  
      alignItems: 'center'       
    },
    section: {
      display: 'flex',           
      alignItems: 'center',      
      marginRight: '20px'        
    },
    icon: {
      marginRight: '10px',  
    }
  };

  return (
    <div style={styles.container}>
      
      <div style={styles.section}>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400
          }}
        >
        ¡Sí, leíste bien! Todos nuestros precios incluyen IVA.</Typography>
      </div>

    </div>
  );
};

export default Datos;
