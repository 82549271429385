import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Snackbar, IconButton } from '@mui/material';
import ItemCount from './Itemcount';
import { toCapital } from '../../Helpers/toCapital';
import { CartContext } from '../../Context/CardContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSwipeable } from 'react-swipeable';

const BASE_URL = 'https://onloop.cl/images/Producto/';
const FALLBACK_IMAGE = 'https://onloop.cl/images/Imagen_no_disponible.jpg';

const ItemDetail = ({ item }) => {
  const [cantidad, setCantidad] = useState(1);
  const [agregadoAlCarrito, setAgregadoAlCarrito] = useState(false);
  const [errorStock, setErrorStock] = useState(false);
  const { agregarAlCarrito } = useContext(CartContext);
  const [imagenPrincipal, setImagenPrincipal] = useState(`${BASE_URL}${item.imagen}`);
  const [miniaturas, setMiniaturas] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const folderName = item.imagen.split('.')[0];

  useEffect(() => {
    const fetchMiniaturas = async () => {
      const miniaturaFolder = `${BASE_URL}${folderName}/`;
      const urls = Array.from({ length: item.imagenesDisponibles || 5 }, (_, index) =>
        `${miniaturaFolder}${index + 1}.jpg`
      );

      const validMiniaturas = [];
      for (const url of urls) {
        try {
          const img = new Image();
          img.src = url;
          await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
          });
          validMiniaturas.push(url);
        } catch {
          console.warn(`Miniatura no válida: ${url}`);
        }
      }

      setMiniaturas(validMiniaturas);
    };

    fetchMiniaturas();
  }, [item.imagen, item.imagenesDisponibles]);

  const handleRestar = () => {
    cantidad > 1 && setCantidad(cantidad - 1);
  };

  const handleSumar = () => {
    if (cantidad < item.stock) {
      setCantidad(cantidad + 1);
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  };

  const handleAgregarAlCarrito = () => {
    const resultado = agregarAlCarrito(item, cantidad);
    if (resultado.success) {
      setAgregadoAlCarrito(true);
      setTimeout(() => {
        setAgregadoAlCarrito(false);
      }, 3000);
    } else {
      setErrorStock(true);
      setTimeout(() => {
        setErrorStock(false);
      }, 3000);
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < miniaturas.length - 3) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const getCarouselImages = () => {
    const imagesToShow = [];
    for (let i = 0; i < 3; i++) {
      if (miniaturas[currentImageIndex + i]) {
        imagesToShow.push(miniaturas[currentImageIndex + i]);
      }
    }
    return imagesToShow;
  };

  const handleImageClick = (index) => {
    const selectedImage = miniaturas[currentImageIndex + index];
    if (selectedImage) {
      setImagenPrincipal(selectedImage);
    }
  };

  const handleImageError = () => {
    setImagenPrincipal(FALLBACK_IMAGE);
  };
  

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextImage,
    onSwipedRight: handlePreviousImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        margin: '4rem 0',
        '@media (min-width: 768px)': {
          flexDirection: 'row',
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <img
            src={imagenPrincipal}
            onError={handleImageError}
            alt={item.titulo}
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Box>
        {miniaturas.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              padding: '0.5rem',
              border: '1px solid #ccc',
              borderRadius: '8px',
              position: 'relative',
              width: '100%',
              maxWidth: '400px',
              margin: '0 auto',
            }}
            {...swipeHandlers}
          >
            <IconButton
              onClick={handlePreviousImage}
              sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
              disabled={currentImageIndex === 0}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Box sx={{ display: 'flex', overflow: 'hidden', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              {getCarouselImages().map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Miniatura ${index}`}
                  style={{
                    width: '100px',
                    height: 'auto',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    border: imagenPrincipal === url ? '2px solid blue' : 'none',
                    transition: 'transform 0.2s',
                  }}
                  onClick={() => handleImageClick(index)}
                />
              ))}
            </Box>
            <IconButton
              onClick={handleNextImage}
              sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
              disabled={currentImageIndex >= miniaturas.length - 3}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: '1.5rem',
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          {item.titulo}
        </Typography>
        <Typography 
          variant="body2"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem'
          }}
        >
          Categoría: {toCapital(item.categoria)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem'
          }}
        >
          SKU: {toCapital(item.modelo)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            textAlign: 'justify',
            textAlignLast: 'left',
            textJustify: 'inter-word',
            whiteSpace: 'pre-line',
          }}
        >
          {item.descripcion}
        </Typography>
        <Typography variant="h5" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
          {formatPrice(item.precio)}
        </Typography>
        <ItemCount
          cantidad={cantidad}
          handleSumar={handleSumar}
          handleRestar={handleRestar}
          handleAgregar={handleAgregarAlCarrito}
          stock={item.stock}
        />
        <Snackbar
          open={agregadoAlCarrito}
          autoHideDuration={3000}
          onClose={() => setAgregadoAlCarrito(false)}
          message={`Se han agregado ${cantidad} ${item.titulo} al carrito.`}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
        <Snackbar
          open={errorStock}
          autoHideDuration={3000}
          onClose={() => setErrorStock(false)}
          message="No se puede añadir más de lo disponible en stock"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
      </Box>
    </Box>
  );
};

export default ItemDetail;
