import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import IsotipoOnLoop from '../Image/Footer/ONLOOP_ISOTIPO.svg';
import TictokLogo from '../Image/Footer/tiktok.svg';
import FacebookLogo from '../Image/Footer/facebook.svg';
import InstagramLogo from '../Image/Footer/instagram.svg';
import MercadoPagoLogo from '../Image/Footer/mercadopago.svg';
import WhatsappLogo from '../Image/Footer/whatsapp.png';
import WebPayLogo from '../Image/Footer/webpay-cl.png';

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleWhatsAppClick = () => {
    const phoneNumber = "+56929587626";
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const url = isMobile
      ? `https://wa.me/${phoneNumber}`
      : `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <Box mt={5} py={3} px={2} bgcolor="#333" color="white" boxShadow={2}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={3} textAlign={isSmallScreen ? 'center' : 'left'}>
          <Box mb={isSmallScreen ? 2 : 0} mx={isSmallScreen ? 0 : 12}>
            <img src={IsotipoOnLoop} alt="OnLoop Logo" height="100" />
          </Box>
          <Box mt={2} textAlign={isSmallScreen ? 'center' : 'left'} mx={isSmallScreen ? 0 : 8}>
            <Box display="flex" justifyContent={isSmallScreen ? 'center' : 'flex-start'} alignItems="center">
              <img
                src={WhatsappLogo} alt="WhatsApp" height="35" style={{cursor: 'pointer',filter: 'invert(1)'}} onClick={handleWhatsAppClick}
              />
              <a href="https://www.tiktok.com/@onloop.cl" target="_blank" rel="noopener noreferrer">
                <img src={TictokLogo} alt="Tictok" height="35" style={{ marginLeft: '8px' }} />
              </a>
              <a href="https://web.facebook.com/people/Onloop/61565829035656/" target="_blank" rel="noopener noreferrer">
                <img src={FacebookLogo} alt="Facebook" height="40" style={{ marginLeft: '8px' }} />
              </a>
              <a href="https://www.instagram.com/onloop.cl/" target="_blank" rel="noopener noreferrer">
                <img src={InstagramLogo} alt="Instagram" height="40" style={{ marginLeft: '8px' }} />
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} textAlign="center">
          <Typography
              variant="h6"
              gutterBottom
              sx={{
                mt: 1,
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 700
              }}
            >
            Información
            </Typography>
           <br></br>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400
              }}
            >
              Oficina: Av. Pajaritos 3030, Oficina 911, Maipú, Región Metropolitana (sin atención a publico). <br/>
            </Typography><br></br>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400
              }}
            >
              Bodega: Av. Lo espejo 860, Maipú, Región Metropolitana. Bodega 39. <br />
            </Typography>
        </Grid>
        <Grid item xs={12} sm={3} textAlign="center">
          <Typography
              variant="h6"
              gutterBottom
              sx={{
                mt: 1,
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 700
              }}
            >
            Contáctanos
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400
              }}
            >
            contacto@onloop.cl<br />
              {/* ventas@onloop.cl<br /> */}
              {/* +56987654321<br /> */}
              +56929587626
            </Typography>
        </Grid>
        <Grid item xs={12} sm={3} textAlign={isSmallScreen ? 'center' : 'right'}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Medios de Pago
            </Typography>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <img
                src={WebPayLogo}
                alt="WebPay"
                style={{ maxWidth: '150px', width: '100%' }}
              />
              <img
                src={MercadoPagoLogo}
                alt="MercadoPago"
                style={{ maxWidth: '150px', width: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="body2" mt={3} textAlign="center" sx={{ fontFamily: 'Outfit, sans-serif', fontWeight: 700}}>
        © 2024 OnLoop. All rights reserved.
        {isSmallScreen ? (
          <>
            <br />
            <Link to="/terminos" style={{ color: 'inherit', textDecoration: 'none' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
            Terminos y condiciones
            </Link>
            <br></br>
            <Link to="/politica" style={{ color: 'inherit', textDecoration: 'none' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
            Política de cambios y devoluciones
            </Link>
          </>
        ) : (
          <span style={{ marginLeft: '16px' }}>
            <Link to="/terminos" style={{ color: 'inherit', textDecoration: 'none' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
            Terminos y condiciones
            </Link>
            {' | '}
            <Link to="/politica" style={{ color: 'inherit', textDecoration: 'none' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }}>
            Política de cambios y devoluciones
            </Link>
          </span>
        )}
      </Typography>
      {!isSmallScreen && (
        <Typography variant="body2" style={{ marginTop: '8px' , fontFamily: 'Outfit, sans-serif', fontWeight: 700 }} textAlign="center">
          Hecho con ❤️ por Onloop
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
