import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Carrucel = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  const formatPrice = (price) => {
    const numericPrice = Number(price);
    return isNaN(numericPrice) 
      ? 'Precio no disponible' 
      : numericPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  };

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.get('https://onloop.cl/api/productos.php');
        if (response.data.success) {
          const shuffledData = response.data.data.sort(() => 0.5 - Math.random());
          const selectedItems = [];
          const ids = new Set();

          for (let item of shuffledData) {
            if (selectedItems.length === 8) break;
            if (!ids.has(item.id)) {
              selectedItems.push(item);
              ids.add(item.id);
            }
          }

          setItems(selectedItems);
        } else {
          setError(response.data.message);
          console.error('Error en la respuesta de la API:', response.data.message);
        }
      } catch (error) {
        setError('Error al obtener los productos: ' + error.message);
        console.error('Error al obtener los productos:', error);
      }
    };

    fetchProductos();
  }, []);

  // Imagen de reserva si no existe la imagen del producto
  const getImageUrl = (imagen) => {
    const defaultImage = "https://onloop.cl/images/Imagen_no_disponible.jpg";
    const imageUrl = imagen ? `https://onloop.cl/images/Producto/${imagen}?t=${new Date().getTime()}` : defaultImage;
    return imageUrl;
  };

  return (
    <div>
      <Carousel responsive={responsive}>
        {items.map((item) => {
          const imageUrl = getImageUrl(item.imagen);

          return (
            <div key={item.id} style={{ padding: '10px' }}>
              <Link to={`/item/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    src={imageUrl}
                    alt={item.titulo}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      // Si la carga de la imagen falla, sustituye por la imagen por defecto
                      e.target.src = 'https://onloop.cl/images/Imagen_no_disponible.jpg';
                    }}
                  />
                </div>
                <p style={{ textAlign: 'center' }}>{item.titulo}</p>
                <p style={{ textAlign: 'center' }}>{formatPrice(item.precio)}</p>
              </Link>
            </div>
          );
        })}
      </Carousel>
      {error && (
        <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
      )}
    </div>
  );
};

export default Carrucel;
