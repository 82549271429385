import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

function Rechazado() {
  const navigate = useNavigate();

  useEffect(() => {
    const paymentStatus = localStorage.getItem('payment_status');

    if (paymentStatus !== 'rejected') {
      navigate('/');
    }

    localStorage.removeItem('payment_status');

    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h4" color="error.main">
        ¡Compra rechazada!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Hubo un problema con su compra. Por favor, inténtelo nuevamente.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
        Será redirigido a la página principal en unos momentos...
      </Typography>
    </div>
  );
}

export default Rechazado;
