import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link, Link as RouterLink } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: '2rem',
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            marginBottom: '2rem',
          }}
        >
          Términos y condiciones de los productos y servicios
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Generalidades
        </Typography>
        
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          OnLoop es una tienda online, dedicada a la venta de una amplia variedad de productos, como juguetes, lockers, mobiliario para oficinas, y muebles para el hogar. 
          Debido a que los presentes Términos y Condiciones son vinculantes entre OnLoop y sus usuarios, y se comprenden aceptados por estos últimos desde el momento en que acceden el sitio, recomendamos leerlos detenidamente. Los eventuales nuevos productos o servicios que se incorporen a la oferta de OnLoop, también se encontrarán sujetos a los presentes términos. Asimismo, nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los presentes términos de los productos o servicios mediante la publicación de actualizaciones o cambios en nuestra página web, siendo responsabilidad del usuario revisar este contenido periódicamente.
          Los actos que se ejecuten o los contratos que celebren por medio de este sitio web, se encuentran sujetos y sometidos a las leyes de la República de Chile, particularmente respecto de la ley 19.496 sobre Protección de los Derechos de los Consumidores. Asimismo, OnLoop adhiere en todas sus partes al Código de Buenas Prácticas para el Comercio Electrónico de la Cámara de Comercio de Santiago.
          Los encabezados utilizados en este acuerdo se incluyen solo para facilitar su lectura, razón por la cual, no limitarán ni afectarán la interpretación del presente documento.

        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Comunicaciones
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Respecto de la información publicitaria o promocional que OnLoop le envíe por correo electrónico, ésta contendrá al menos la siguiente información: a) Identificación del mensaje en su asunto, que lo haga reconocible como publicidad o comunicación promocional; b) Identificación del anunciante con su denominación comercial; c) Existencia del derecho del consumidor o usuario a solicitar el cese de envíos de publicidad de ese tipo; y d) Un procedimiento simple y eficaz para que el consumidor pueda hacer esa solicitud. OnLoop cesará el envío de los mensajes publicitarios o promocionales por correo electrónico u otros medios de comunicación individual equivalentes, a toda persona que hubiere solicitado expresamente la suspensión de esos envíos.
          Para la comunicación de cualquier presentación, consulta o reclamo a propósito de las compras de productos y servicios en este sitio, OnLoop designa como representante para estos efectos a Scarleth Palacios, disponible en el correo electrónico contacto@onloop.cl, domiciliado para estos efectos en Avenida Central Gonzalo Pérez Llona 1255, comuna de Maipú, ciudad de Santiago. 

        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Registro
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Quien desee comprar por intermedio de nuestra página, deberá completar el formulario de información requerido, con todos los datos del cliente que sean necesarios para autentificar la compra y entrega del producto.  De este modo, el usuario se compromete a completar la información de manera exacta, precisa y verdadera y a proporcionar sus datos siempre actualizados. Sin perjuicio de la información brindada en el formulario, eventualmente podremos solicitar y/o consultar información adicional para corroborar la identidad del suscribiente.
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Compras
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Para hacer compras o contratar servicios en este sitio deberá seguir los siguientes pasos: 
          1. Seleccione el producto o servicio que le interesa y agréguelo a su “carro de compra”. El producto indicará su stock o cantidad de productos disponibles para la venta, más referencias como, por ejemplo, sus dimensiones, color, peso, número de unidades que lo componen, el material, las restricciones de uso y los cuidados relevantes. Previo a la realización del pago, se informará el costo total de la compra o servicio. 
          2. Confirme haber leído y aceptado estos Términos y Condiciones.
          3. Ingrese los antecedentes requeridos en el formulario de registro.
          4. Seleccione el tipo de despacho o entrega entre las alternativas disponibles en el sitio.
          5. Seleccione el medio de pago.
          6. Una vez efectuada la orden, se desplegará en la pantalla información sobre el producto o servicio contratado, precio, costos de envió, medios de pago, y valor total de operación.
          7. La orden luego pasará automáticamente a un proceso de confirmación de identidad, resguardándose siempre la seguridad y privacidad del usuario y del proceso de contratación, disponibilidad, vigencia y cupo del medio de pago que se haya seleccionado; lo cual será efectuado por la empresa Mercado Pago.
          8. Envío de la confirmación de su compra información al correo electrónico registrado.

        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Pago
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El pago de los productos se realizará mediante la plataforma de Mercado Pago, empresa que dispone de sus propios Términos y Condiciones de Servicios. La forma de realizar el pago será mediante tarjetas de crédito o débito o transferencia electrónica. En el evento de realizarse un doble pago por un usuario o comprador en el Sitio, OnLoop devolverá la suma del sobrepago, dentro de los 3 días hábiles siguientes a la recepción del respectivo reclamo escrito del usuario o comprador, en el que se anexen los originales de los comprobantes del pago adicional a lo adquirido.
          OnLoop no manipula ni almacena datos financieros de sus clientes debido a que el servicio de pago se realiza mediante la plataforma de Mercado Pago. No obstante, cualquier responsabilidad eventual, contractual, extracontractual o legal, con los usuarios, compradores o visitantes de El Sitio no excederá del precio efectivamente pagado por el comprador en contraprestación por el producto o servicio, sin perjuicio de lo que determinen los Tribunales de Justicia.
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Modificaciones
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Los precios de nuestros productos o modalidades de despachos o entregas, están sujetos a cambios sin previo aviso. Sin embargo, estas modificaciones solo afectarán a los productos adquiridos con posterioridad al cambio. Asimismo, OnLoop se reserva el derecho de modificar o descontinuar determinados productos en cualquier momento.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Despacho y entrega
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Las condiciones de despacho y entrega de los productos adquiridos podrán ser elegidas de entre las opciones ofrecidas en el sitio. Si se elige retirar el producto en tienda, deberá realizarse en el horario en que ésta se encuentre abierta al público, esto es Lunes a Jueves de 9 a 17 horas. Se informará mediante correo electrónico registrado, que el producto se encuentra a disposición y el plazo para ser retirado.
          Las entregas son realizadas por empresas de servicios externas a OnLoop, las cuales retiran los productos de nuestras bodegas y realizan el despacho a los clientes en conformidad a sus tiempos y rutas. Cualquier retraso, daño o extravío de sus productos será responsabilidad de la empresa encargada del despacho o entrega. No obstante, solicitamos contactar a OnLoop si se presentaran daños o inconvenientes de cualquier índole o alcance, para buscar alternativas o soluciones a su problema en un plazo de 90 días, desde comunicado a OnLoop el respectivo reclamo o denuncia. 
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Responsabilidad
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          OnLoop, nunca responderá por: a) La utilización indebida que usuarios o visitantes del sitio puedan hacer de los materiales exhibidos, de los derechos de propiedad industrial y de los derechos de propiedad intelectual propios y de terceros; b) Daños o eventuales perjuicios a los clientes o usuarios por el funcionamiento de las herramientas de búsqueda y de los errores que se generen por los elementos técnicos del sitio; c) Acceso de menores de edad o personas sin capacidad, bajo los términos de la legislación correspondiente, a los contenidos inherentes a la relación contractual que surjan mediante la utilización de este sitio, así como la adquisición de productos o servicios; d) Pérdida, mal uso o uso no autorizado de su código de validación, ya sea por parte de usuarios, clientes, o de terceros, luego de realizada una compra en la forma expresada en los Términos y Condiciones. Además, las partes reconocen y dejan constancia que la plataforma computacional proporcionada por OnLoop no es infalible y, por lo tanto, pueden verificarse circunstancias ajenas a su voluntad, que afecten la funcionalidad del Sitio o plataforma computacional durante un determinado periodo de tiempo; e) OnLoop no se hace responsable por virus u otros elementos en los documentos electrónicos almacenados en los sistemas informáticos de los Usuarios, no respondiendo de los perjuicios ocasionados provenientes del uso inadecuado de las tecnologías puestas a disposición de éste, cualquiera sea la forma en la cual se utilicen inadecuadamente. Tampoco responderá de los daños producidos al Sitio por el uso indebido y/o de mala fe, de los usuarios y/o compradores. 
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Comentarios de los usuarios y comunicaciones libres de violencia
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El usuario es el único responsable de los comentarios que realice, sin que OnLoop asuma alguna responsabilidad u obligación por los comentarios publicados. Además, el usuario acepta que sus comentarios no contendrán material difamatorio, ilegítimo, abusivo u obsceno, violento, ni contendrán ningún virus informático ni otro software dañino. 
          Asimismo, el usuario reconoce mantener un trato cordial y respetuoso con todos los colaboradores de OnLoop, así como respecto de aquellos que se desempeñan en sus empresas relacionadas, en el marco de la protección de un ambiente laboral libre de violencia en el trabajo.  
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Información personal y confidencialidad
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El envío de la información personal que haga a través del sitio o por canales de comunicación internos de OnLoop, se rige por normas de privacidad y por la legislación vigente y futura sobre protección de datos personales. Es por ello, que los datos ingresados por los usuarios no serán entregados a terceros, salvo que deban ser revelados en cumplimiento de una orden judicial o un requerimiento legal. Asimismo, los usuarios y/o clientes garantizan que la información que suministran en el Sitio es veraz, completa, exacta y actualizada; y además, se presume que los datos han sido incorporados por su titular o por persona autorizada por éste, así como que son correctos y exactos.
          OnLoop adoptará las medidas necesarias y prudentes para resguardar la seguridad de los datos, como sistemas de encriptación de información, certificados de seguridad u otros que la empresa estime pertinente. En caso de detectarse cambios en la información que se ha registrado en el sitio, o bien, ante cualquier irregularidad en las transacciones relacionadas con su identificación o la del medio de pago, o simplemente como medida de protección a su identidad, nuestros ejecutivos podrán contactarlo por vía telefónica o correo electrónico, a fin de corroborar sus datos e intentar evitar posibles fraudes.
          En caso de no poder establecer el contacto en un plazo de 72 hrs, por su propia seguridad, su orden de compra efectuada en nuestro sitio no podrá ser confirmada. Le informaremos vía telefónica o por correo electrónico que su orden ha quedado sin efecto por no poder confirmar su identidad o el medio de pago ofrecido 
          De conformidad con la Ley 19.628 los datos personales que se suministren en el Sitio pasarán a formar parte de una base de datos de OnLoop y serán destinados única y exclusivamente para ser utilizados en los fines que motivaron su entrega y especialmente para la comunicación en general entre la empresa y sus clientes, validar los datos de la compra, concretar el despacho y responder sus consultas. Los datos no serán comunicados a otras empresas sin la expresa autorización de su titular ni serán transferidos internacionalmente.
          OnLoop jamás solicita datos personales o financieros a través de correo electrónico.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Documentos electrónicos
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          El usuario en su calidad de receptor manual de documentos electrónicos, de conformidad con la Resolución Exenta N° 11 del 14 de febrero de 2003 del Servicio de Impuestos Internos (que estableció el procedimiento para que contribuyentes autorizados para emitir documentos electrónicos puedan también enviarlos por estos medios a receptores manuales), declara y acepta lo siguiente:
          Al aprobar estos términos y condiciones, el usuario autoriza a la empresa OnLoop y a las empresas que vendan sus productos y servicios en dicha plataforma, para que el documento tributario correspondiente a las transacciones que se realicen, le sea entregado solamente por un medio electrónico. De igual forma, autoriza que el aviso de publicación del documento tributario enviado mediante correo electrónico.
          De conformidad con la normativa indicada, y en caso que el usuario lo requiera para respaldar la información contable, asume en relación a dichos documentos tributarios, las siguientes obligaciones: a) Imprimir los documentos recibidos en forma electrónica, para cada período tributario, en forma inmediata a su recepción desde el emisor.; b) Imprimir el documento en el tamaño y forma que fue generado; c) Utilizar papel blanco tipo original de tamaño mínimo 21,5 cm x 14 cm (1/2 carta) y de tamaño máximo 21,5 x 33 cm (oficio); d) Imprimir en una calidad que asegure la permanencia de la legibilidad del documento durante un periodo mínimo de seis años, conforme lo establece la legislación vigente sobre la materia. Esta impresión se hará hecha usando impresión láser o de inyección de tinta, excepto que se establezca una autorización o norma distinta al respecto.
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Errores, inexactitudes y omisiones
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Puede haber información en nuestro sitio, que involuntariamente, contenga errores tipográficos, inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios, promociones, ofertas y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión y de cambiar o actualizar dicha información en nuestro sitio web, en cualquier momento, sin previo aviso.
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Indemnización
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Usted acepta indemnizar, defender y mantener indemne a OnLoop y a nuestra empresa matriz, subsidiarias, afiliadas, asociados, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores y colaboradores; de cualquier reclamo o demanda, incluidos los honorarios razonables de abogados, en los que un tercero haya incurrido debido a su incumplimiento de los presentes Términos del Sitio vigentes o actualizados, o por la violación de cualquier ley o derechos de terceros.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Horarios
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Queda establecido que nuestro horario de atención a clientes y usuarios es de 9:30 a 18:00 horas de lunes a jueves, y viernes, de 9:30 a 14:00 horas. Sin embargo, se atenderán fuera de ese horario, sólo situaciones consideradas urgentes e imprevistas, cuya valoración de la urgencia será estimada discrecionalmente por nuestro equipo de colaboradores.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Acuerdo
        </Typography>
        
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
        El hecho de que no ejerzamos o hagamos valer algún derecho o disposición de los presentes Términos y Condiciones de los Productos o Servicios no constituirá una renuncia a dicho derecho o disposición.
        Los presentes Términos y Condiciones, así como cualquier política que publiquemos en el sitio constituye el acuerdo y entendimiento completo entre el usuario y OnLoop, sustituyendo a cualquier comunicación anterior o contemporánea, ya sea oral o escrita, entre usted y nosotros.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Ley aplicable
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
        Los presentes Términos y Condiciones de los Productos y Servicios, se regirán e interpretarán conforme a la legislación chilena. Asimismo, todo aquello que revista derechos y obligaciones del consumidor se encuentra resguardado por el DFL 3 que fija el Texto Refundido de la Ley N° 19.496, que establece normas sobre protección de los Derechos de los Consumidores, así como las disposiciones del Reglamento sobre Comercio Electrónico.
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Información de contacto
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
          Las preguntas sobre los Términos del Sitio y de Servicios se deben enviar a{' '}
          <Link
            component={RouterLink}
            to="/contacto"
            sx={{
              color: 'blue',
              textDecoration: 'underline',
              '&:hover': {
                color: 'darkblue',
                textDecoration: 'none',
              },
            }}
          >
            contacto@onloop.cl
          </Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
