import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ItemCount = ({ cantidad, handleRestar, handleSumar, handleAgregar, stock, costeAdicional, categoria }) => {
  return (
    <Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem'
      }}>
        <Button
          variant="contained"
          sx={{ 
            backgroundColor: '#ff4d4d', 
            '&:hover': { backgroundColor: '#e03e3e' }, 
            padding: '4px 37px', 
            fontSize: '0.875rem',
            minWidth: '35px'     
          }}
          onClick={handleRestar}
        >
          -
        </Button>
        <Typography variant="body1" sx={{ fontSize: '1.5rem' }}>{cantidad}</Typography>
        <Button
          variant="contained"
          sx={{ 
            backgroundColor: '#ff4d4d', 
            '&:hover': { backgroundColor: '#e03e3e' }, 
            padding: '4px 37px',
            fontSize: '0.875rem',
            minWidth: '35px'
          }}
          onClick={handleSumar}
        >
          +
        </Button>
      </Box>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#ff4d4d', '&:hover': { backgroundColor: '#e03e3e' }, marginTop: '1rem' }}
        onClick={handleAgregar}
      >
        Agregar al carrito
      </Button>
      {stock < 10 && (
        <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
          Últimas unidades ({stock} disponibles)
        </Typography>
      )}
      {costeAdicional > 0 && (
        <Typography
          variant="body2"
          color="warning"
          sx={{
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            marginTop: '0.5rem', 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          * El envío de 2 o más unidades posee un recargo adicional calculado al momento de la compra.
        </Typography>
      )}
      {categoria !== 'Juguetes' && categoria !== 'Accesorios para mascotas' && (
        <Typography
          variant="body2"
          color="warning"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            marginTop: '0.5rem',
            textAlign: 'justify',
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          * El envío de 3000 solo aplica a las categorías: accesorios de mascotas y juguetes.
        </Typography>
      )}
    </Box>
  );
};

export default ItemCount;
